export class CountDown {
  protected intervalId: null | any = null;

  public count: number | null = null;

  protected action: null | ((...args: any[]) => void | Promise<void>) = null;

  public start(action: null | ((...args: any[]) => void | Promise<void>), countFrom = 60): void {
    if (countFrom < 1) { return; }

    this.action = action;
    this.count = countFrom;

    this.stop();

    this.intervalId = setInterval(() => this.onCount(), 1000);
  }

  public stop(): void {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);

      this.count = null;
      this.intervalId = null;
    }
  }

  protected onCount(): void {
    if (this.count !== null) {
      this.count -= 1;
    }

    if (this.count === 0) {
      this.stop();

      if (this.action) {
        this.action();
      }
    }
  }
}

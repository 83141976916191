import { Component, Vue } from 'vue-property-decorator';
import { Label } from '@/components/List/List';
import { Rpc } from '@/models/Rpc';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import ExportStatus, { ExportStatusEnum } from '@/items/ExportStatus';
import { CountDown } from '@/support/CountDown';
import { isArray } from 'lodash';

@Component<Exports>({})
export default class Exports extends Vue {
  // #region Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  public $pageTitle = 'Exports';

  protected isLoading = false;

  protected labels: Label[] = [
    {
      slotName: 'file_name',
      title: 'Naam',
      width: 'xs3',
    },
    {
      slotName: 'created',
      title: 'Aangevraagd op',
      width: 'xs2',
    },
    {
      slotName: 'generated',
      title: 'Aangemaakt op',
      width: 'xs2',
    },
    {
      slotName: 'status',
      title: 'status',
      width: 'xs2',
    },
    {
      slotName: 'progress',
      title: 'Voortgang',
      width: 'xs1',
    },
    {
      slotName: 'actions',
      width: 'xs2',
      class: 'text-right',
    },
  ];

  protected items: Item[] = [];

  protected ExportStatus = ExportStatus;

  protected ExportStatusEnum = ExportStatusEnum;

  protected countDown: CountDown = new CountDown();

  // //#endregion

  // //#region @Refs
  // /**
  // * ie.
  // * @Ref()
  // * readonly anotherComponent!: AnotherComponent
  // */
  // //#endregion

  // //#region Lifecycle hooks
  // /**
  // * ie.
  // * protected mounted(): void {
  // *  console.log('MyComponent has Mounted');
  // * }
  // */

  public mounted(): void {
    this.onMounted();
  }

  protected beforeDestroy(): void {
    this.onBeforeDestroy();
  }

  // //#endregion

  // //#region Lifecycle hook handlers

  protected onMounted(): void {
    this.initialize();
  }

  protected onBeforeDestroy(): void {
    this.deinitialize();
  }

  // //#endregion

  // #region Lifecycle hook helpers

  protected async initialize(): Promise<void> {
    this.initializeBreadcrumb();

    await this.fetchExports();
  }

  protected deinitialize(): void {
    this.countDown.stop();
  }

  // #endregion

  // #region Handlers

  // protected async onDownloadItem(item: Item): Promise<void> {
  //   await this.downloadItem(item);
  // }

  protected onClickDownload(item: Item): void {
    if (! item.link) return;

    // var aLink = document.createElement('a');
    // var evt = document.createEvent("HTMLEvents");
    // evt.initEvent("click");
    // aLink.download = item.file_name ||;
    // aLink.href = item.link;
    // aLink.click(evt);
  }

  protected async onRefreshButtonClick(): Promise<void> {
    await this.fetchExports();
  }

  // #endregion

  // //#region Helpers
  // /**
  // * ie.
  // * protected toggleLoading(): void {
  // *  this.isLoading = ! this.isLoading;
  // * }
  // */

  protected initializeBreadcrumb(): void {
    this.$root.$emit('breadcrumbUpdated', {
      crumb: [{ name: 'Exports' }],
    });
  }

  // //#endregion

  // //#region API requests
  // /**
  // * ie.
  // * protected async fetchUserCompany(): Promise<void> {
  // *  this.company = await new Company().filter({user: this.user.id}).all();
  // * }
  // */

  protected async fetchExports(): Promise<void> {
    if (this.isLoading) { return; }

    this.isLoading = true;

    this.countDown.stop();

    const myExportsListRpcBody = {
      signature: 'my-exports:list',
      body: {},
    };

    const myExportsListRpcResult = await new Rpc()
      .rpcPost(myExportsListRpcBody)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (myExportsListRpcResult) {
      let items: Item[] = [];

      if (isArray(myExportsListRpcResult)) {
        items = myExportsListRpcResult;
      } else {
        const numericItemKeys = Object.keys(myExportsListRpcResult)
          .map((key: string): null | string => (! isNaN(parseInt(key)) ? key : null))
          .filter((key: null | string): boolean => key !== null) as string[];

        items = numericItemKeys
          .map((key: string): Item => myExportsListRpcResult[key]);
      }

      this.items = items;
    } else {
      this.items = [];
    }

    if (! this.isAllItemsFinished) {
      this.countDown.start(this.fetchExports, 10);
    }

    this.isLoading = false;
  }

  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.file_name || 'N/A';
  * }
  */

  protected get isAllItemsFinished(): boolean {
    return ! this.items.find((item: Item) => item.status === ExportStatusEnum.QUEUED || item.status === ExportStatusEnum.PROCESSING);
  }

  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}

// #region Interfaces

interface Item {
  id?: string;
  file_name?: string;
  status?: ExportStatusEnum;
  link?: null | string;
  created_at?: string;
  generated_at?: string;
  mime_type?: string;
}

// #endregion

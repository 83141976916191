export enum ExportStatusEnum {
  QUEUED = 'queued',
  PROCESSING = 'processing',
  FINISHED = 'finished',
}

export interface ExportStatusItemInterface {
  value: ExportStatusEnum;
  text: string;
}

export default class ExportStatus {
  public static get items(): ExportStatusItemInterface[] {
    return Object
      .keys(ExportStatus.texts)
      .map((value: string): ExportStatusItemInterface => ({
        value: value as ExportStatusEnum,
        text: ExportStatus.texts[value as ExportStatusEnum],
      }));
  }

  protected static get texts(): Record<ExportStatusEnum, string> {
    return {
      [ExportStatusEnum.QUEUED]: 'In wachtrij',
      [ExportStatusEnum.PROCESSING]: 'Wordt gegenereerd',
      [ExportStatusEnum.FINISHED]: 'Gereed',
    };
  }
}

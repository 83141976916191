var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "exports-page" },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Mijn exports")
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              {
                staticClass:
                  "elementPanel elementPanel--spaced tw-text-primary-500"
              },
              [
                _vm._v(
                  " Indien het niet mogelijk is om zelf een Excel export aan te maken, kun je deze aanvragen via helpdesk@schadedoormijnbouw.nl. "
                )
              ]
            )
          ]),
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel" },
              [
                _vm.isLoading
                  ? _c("LoaderCard", {
                      attrs: { flat: "", type: "spinner--center" }
                    })
                  : _vm._e(),
                !_vm.isLoading
                  ? _c("List", {
                      attrs: { labels: _vm.labels, items: _vm.items },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "actions-label",
                            fn: function() {
                              return [
                                _c(
                                  "v-layout",
                                  { attrs: { "justify-end": "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { shrink: "" } },
                                      [
                                        _c(
                                          "MiButton",
                                          {
                                            class: {
                                              "exports-page__refresh-button":
                                                _vm.countDown.count
                                            },
                                            attrs: {
                                              fullWidth: true,
                                              icon: "sync",
                                              compact: true
                                            },
                                            nativeOn: {
                                              click: function($event) {
                                                return _vm.onRefreshButtonClick(
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " refresh " +
                                                _vm._s(
                                                  _vm.countDown.count !== null
                                                    ? "(" +
                                                        _vm.countDown.count +
                                                        ")"
                                                    : ""
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "file_name",
                            fn: function(item) {
                              return [
                                _vm._v(" " + _vm._s(item.file_name) + " ")
                              ]
                            }
                          },
                          {
                            key: "created",
                            fn: function(item) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFormatTime")(item.created_at)
                                    ) +
                                    " "
                                )
                              ]
                            }
                          },
                          {
                            key: "generated",
                            fn: function(item) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFormatTime")(
                                        item.generated_at
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            }
                          },
                          {
                            key: "progress",
                            fn: function(item) {
                              return [
                                _c(
                                  "v-layout",
                                  [
                                    _c("v-flex", { attrs: { xs12: "" } }, [
                                      _vm._v(" " + _vm._s(item.progress) + "% ")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "status",
                            fn: function(item) {
                              return [
                                _c(
                                  "v-layout",
                                  { attrs: { "pr-3": "" } },
                                  [
                                    _c("v-flex", { attrs: { xs6: "" } }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ExportStatus.texts[
                                              item.status
                                            ] || item.status
                                          ) +
                                          " "
                                      )
                                    ]),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs6: "" } },
                                      [
                                        item.status ===
                                        _vm.ExportStatusEnum.QUEUED
                                          ? _c("v-progress-linear", {
                                              attrs: { value: 0 }
                                            })
                                          : _vm._e(),
                                        item.status ===
                                        _vm.ExportStatusEnum.PROCESSING
                                          ? _c("v-progress-linear", {
                                              attrs: { indeterminate: true }
                                            })
                                          : _vm._e(),
                                        item.status ===
                                        _vm.ExportStatusEnum.FINISHED
                                          ? _c("v-progress-linear", {
                                              attrs: { value: 100 }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "actions",
                            fn: function(item) {
                              return [
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { "justify-end": "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { shrink: "" } },
                                        [
                                          item.status ===
                                          _vm.ExportStatusEnum.FINISHED
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "a",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    href:
                                                                      item.link,
                                                                    download: ""
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "file_download"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [_vm._v(" Bestand downloaden ")]
                                              )
                                            : _c(
                                                "v-icon",
                                                {
                                                  staticClass: "text-gray-500"
                                                },
                                                [_vm._v("file_download")]
                                              )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2186184515
                      )
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }